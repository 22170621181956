import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isFunction } from '@veraio/core';
import { Tag, ButtonArrow } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { paymentMethods, paymentStatus, paymentStatusColor, paymentStatusInverse, paymentStatusSelect } from 'enums';
import { PriceLabel } from 'components/shared';
import { PayExternalPaymentAsyncButton } from './PayExternalPaymentAsyncButton';

export const OrderDetailsPaymentWallet = ({
  iconName,
  title,
  subTitle,
  printPriceProps,
  orderPaymentStatus,
  editable,
  onChange,
  disabled,
  paymentMethod,
  orderId
}) => {
  const { getText } = useTranslations();
  const isActiveCardPayment = paymentMethod === paymentMethods.card
    && orderPaymentStatus === paymentStatus.pending;
  const handleChange = statusId => isFunction(onChange) && onChange(statusId);

  const paymentOptionsConfig = paymentStatusSelect.map(option => ({
    ...option,
    color: paymentStatusColor[option.value],
    label: getText(option.label),
    disabled: option.value === paymentStatus.completed || disabled,
  }));

  return (
    <div className="order-details-payment-method-card">
      {!!iconName && <Icon iconName={iconName} className="order-details-payment-method-avatar" />}
      <div className="order-details-payment-method">
        <h5>{title}</h5>
        <p className="gray order-details-payment-sub-label">{subTitle}</p>
      </div>
      <div className="order-details-payment-price-container" data-id="order-details-payment-price-container">
        <h5 className="order-details-payment-price">
          <PriceLabel {...printPriceProps} />
        </h5>
        <div className="flex">
          {isActiveCardPayment && <PayExternalPaymentAsyncButton orderId={orderId} />}
          {editable ? (
            <ButtonArrow value={orderPaymentStatus} onChange={handleChange} options={paymentOptionsConfig} />
          ) : (
            <Tag type={paymentStatusColor[orderPaymentStatus]} fontSize="0.8rem">
              {paymentStatusInverse[orderPaymentStatus]}
            </Tag>
          )}
        </div>
      </div>
    </div>
  );
};

OrderDetailsPaymentWallet.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  printPriceProps: PropTypes.object,
  orderPaymentStatus: PropTypes.number,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  paymentMethod: PropTypes.number,
  orderId: PropTypes.number,
};
