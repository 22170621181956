import { paymentMethodsInverse, paymentMethods } from 'enums';

export const paymentTypeBoxesConfig = {
  [paymentMethods.cashOnDelivery]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.cashOnDelivery],
    subTitle: 'toBePaidByUserOnDelivery',
  },
  [paymentMethods.bankTransfer]: {
    icon: 'las la-university',
    title: paymentMethodsInverse[paymentMethods.bankTransfer],
    subTitle: 'toBePaidByUserThroughBank',
  },
  [paymentMethods.cashWallet]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.cashWallet],
    subTitle: 'paidByDuringCheckout',
  },
  [paymentMethods.oneWallet]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.oneWallet],
    subTitle: 'paidByDuringCheckout',
  },
  [paymentMethods.oespWallet]: {
    icon: 'las la-coins',
    title: paymentMethodsInverse[paymentMethods.oespWallet],
    subTitle: 'paidByDuringCheckout',
  },
  [paymentMethods.usdt]: {
    icon: 'las la-wallet',
    title: paymentMethodsInverse[paymentMethods.usdt],
    subTitle: 'paidByCoinpayments',
  },
  [paymentMethods.card]: {
    icon: 'las la-credit-card',
    title: 'debitOrCreditCard',
    subTitle: 'paidByBankIssuedCard'
  },
};
