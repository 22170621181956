import { css } from 'emotion';

export const summaryBox = css({
  padding: '1.25em',
  boxShadow: '0px 0px 8px rgba(34, 34, 34, 0.12)',
  borderRadius: 8,
  position: 'sticky',
  top: '10.625em',
});

export const spaceRowSection = color =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color,
    marginBottom: '0.75em',

    '& > .subtotal': {
      marginRight: '1em',
    },
  });

export const rightText = css({
  textAlign: 'right',
});

export const totalSection = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0 1em',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 600,
  fontSize: '1.125rem',
});

export const regularTextStyles = theme =>
  css({
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.gray300,
  });

export const goToCheckoutButton = theme =>
  css({
    marginTop: '1.5em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .insufficientFunds': {
      color: theme.red,
      fontSize: '0.75rem',
    },
  });

export const disclaimerText = css({
    fontSize: '0.75em',
    color: '#888F97',
    borderTop: '0.05em solid #E5E5E5',
    marginTop: '1em',
    paddingTop: '1em',

});
