/* eslint-disable default-case */
import React, { useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslations } from '@veraio/strank';
import { Row, Col, useDeepEffect } from 'components/UIExternal';
import { useStepIndicator } from 'components/shared';
import { SummaryPricesCard, LoadingCreateOrder } from 'components/screens';
import { useShoppingCart, useUser } from 'stores';
import { Routes, paymentMethods } from 'enums';
import { createOrder } from 'services';
import useError from 'services/errorHandling/useError';
import CheckoutDelivery from './Delivery';
import CheckoutPaymentMethods from './PaymentMethods';
import CheckoutSummary from './Summary';
import { mainContainer, sectionTitle, stepsIndicatorContainer } from './styles';

const checkoutSteps = {
  delivery: 'delivery',
  paymentMethod: 'paymentMethod',
  summary: 'summary',
};

const ShoppingCartCheckout = ({ history }) => {
  const theme = useTheme();
  const { getText, language } = useTranslations();
  const { updateUserCurrentAccount } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const {
    shoppingCart,
    updateDeliveryAddress,
    updatePaymentMethods,
    emptyShoppingCart,
    resetDeliveryAddress,
  } = useShoppingCart();
  const { setError } = useError();
  const hasAddresses = shoppingCart?.businesses?.every(business =>
    business?.items?.every(
      item => (item?.selectedBusinessAddressId || item?.selectedUserAddressId) && item?.canBeDelivered,
    ),
  );
  const hasPayments = !!(shoppingCart?.cashPaymentMethodId && shoppingCart?.cryptoPaymentMethodId);
  const [StepIndicator, StepsContent] = useStepIndicator({
    steps: [
      {
        label: checkoutSteps.delivery,
        completed: hasAddresses,
        route: {
          path: Routes.shoppingCartCheckoutDelivery,
          component: <CheckoutDelivery onSubmit={updateDeliveryAddress} onResetAddress={resetDeliveryAddress} />,
        },
      },
      {
        label: checkoutSteps.paymentMethod,
        completed: hasAddresses && hasPayments,
        route: {
          path: Routes.shoppingCartCheckoutPaymentMethods,
          component: <CheckoutPaymentMethods onSubmit={updatePaymentMethods} />,
        },
      },
      {
        label: checkoutSteps.summary,
        completed: hasAddresses && hasPayments,
        route: {
          path: Routes.shoppingCartCheckoutSummary,
          component: <CheckoutSummary onSubmit={() => handleCreateOrder()} />,
        },
      },
    ],
  });

  useDeepEffect(() => {
    !shoppingCart?.businesses?.length && !isLoading && history.replace(Routes.shoppingCart);
  }, [shoppingCart]);

  const handleCreateOrder = async () => {
    setIsLoading(true);
    const [res, err] = await createOrder({ languageCode: language?.code });
    if (err) {
      setIsLoading(false);
      return setError(err);
    }

    const isCardPayment = shoppingCart?.cashPaymentMethodId === paymentMethods.card;

    emptyShoppingCart();
    updateUserCurrentAccount();

    if (res.length > 0) {
      const orderId = res[0];

      if (isCardPayment && orderId)
        history.push({ pathname: Routes.payOrderWithCard.replace(':orderId', orderId) });
      else
        history.push({ pathname: `${Routes.myProfile}${Routes.orders}` });
    } else
      setError({ data: { errors: [{ code: 'emptyOrder', }] } });
  };

  return (
    <section className={`content-container ${mainContainer(theme)}`}>
      <h2 className={sectionTitle(theme)}>{getText('checkout')}</h2>
      {isLoading ? (
        <LoadingCreateOrder />
      ) : (
        <Row gap={8}>
          <Col lg={2} md={12}>
            <StepIndicator className={stepsIndicatorContainer} />
          </Col>
          <Col lg={6} md={12}>
            {shoppingCart && <StepsContent shoppingCart={shoppingCart} onBack={() => history.goBack()} />}
          </Col>
          <Col lg={4} md={12}>
            {shoppingCart && <SummaryPricesCard shoppingCart={shoppingCart} />}
          </Col>
        </Row>
      )}
    </section>
  );
};

export default ShoppingCartCheckout;
