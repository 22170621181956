import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { setError, getOrderExternalPayment } from 'services';
import { STRIPE_PAYMENT_STATUS } from 'enums/stripe';
import { Button } from 'components';
import { payNow } from './styles';

export const PayExternalPaymentAsyncButton = ({ orderId }) => {
  const { getText } = useTranslations();
  const [activePayment, setActivePayment] = useState(false);

  useEffect(() => {
    fetchExternalPayments();
  }, [orderId]);

  const fetchExternalPayments = async () => {
    setActivePayment(false);
    const [res, err] = await getOrderExternalPayment(orderId);

    if (err) return setError(err);

    if (
      res?.statusText === STRIPE_PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD
      && res?.timeExpires > Math.floor(Date.now() / 1000)
    )
      setActivePayment(true);
  };

  return (activePayment && (
    <Button type="danger" small linkTo={`/order/${orderId}/pay-with-card`} className={payNow}>
      {getText('payNow')}
    </Button>
  ));
};

PayExternalPaymentAsyncButton.propTypes = {
  orderId: PropTypes.number,
};
